@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');

.rdw-option-wrapper {
  border: 1px solid #c4c4c4 !important;
  background: #c4c4c4 !important;
  text-transform: capitalize;
}
.rdw-inline-wrapper {
  margin-bottom: 0px !important;
}
.rdw-editor-main {
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  padding: 0 10px;
  min-height: 130px;
}
.rdw-link-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 0px !important;
  position: relative;
  flex-wrap: wrap;
}
.rdw-list-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 0px !important;
  flex-wrap: wrap;
}
.rdw-text-align-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 0px !important;
  flex-wrap: wrap;
}
.article-lesson-content p {
  margin-bottom: 1rem;
  margin-top: 0;
}

.page-edu-loader {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
}
.page-edu-loader::before,
.page-edu-loader::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid var(--chakra-colors-brand-primary);
  animation: prixClipFix 2s linear infinite;
}
.page-edu-loader::after {
  border-color: var(--chakra-colors-brand-accent);
  animation: prixClipFix 2s linear infinite, rotate 0.5s linear infinite reverse;
  inset: 6px;
}

/* Dynamic html styles */

.course_content_remote,
.page-content-html {
  color: #000;
}
.course_content_remote p,
.page-content-html a {
  font-size: 16px;
  line-height: 26px;
  text-align: justify;
  margin-bottom: 10px;
  margin-left: 0 !important;
  color: #000;
}
.page-content-html p {
  margin-bottom: 10px;
  color: #000;
}

.course_content_remote a,
.page-content-html a {
  color: #4285f4;
  text-decoration: underline;
}

.course_content_remote ul,
.page-content-html ul,
.course_content_remote ol,
.page-content-html ol {
  margin-bottom: 20px;
  margin-top: 20px;
  margin-left: 25px;
}

.course_content_remote li,
.page-content-html li {
  line-height: 26px;
  text-align: justify;
  list-style: initial;
  padding-bottom: 10px;
}

.page-content-html h1,
.page-content-html .h1 {
  margin-bottom: 30px;
  font-size: 40px;
}

.page-content-html h2,
.page-content-html .h2 {
  font-size: 25px;
  padding-bottom: 10px;
  margin-bottom: 1rem;
  margin-top: 1.5rem;
}

.page-content-html h3,
.page-content-html .h3 {
  font-size: 25px;
  padding-bottom: 10px;
  margin-bottom: 1rem;
  margin-top: 1.5rem;
}

.page-content-highlight strong {
  font-size: 24px;
  color: #000;
  padding-bottom: 10px;
}

/* typing Indicator */

.typing__dot {
  float: left;
  width: 8px;
  height: 8px;
  margin: 0 4px;
  background: #8d8c91;
  border-radius: 50%;
  opacity: 0;
  animation: loadingFade 1s infinite;
}

.typing__dot:nth-child(1) {
  animation-delay: 0s;
}

.typing__dot:nth-child(2) {
  animation-delay: 0.2s;
}

.typing__dot:nth-child(3) {
  animation-delay: 0.4s;
}

/* branded radio button styles  */
.create-quiz-radio {
  height: fit-content;
}
.branded_radio-button input[type='radio'] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0;
  font: inherit;
  width: 1.25em;
  height: 1.25em;
  border: 1px solid #000;
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

.branded_radio-button input[type='radio']::before {
  content: '';
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  /* Windows High Contrast Mode */
  background-color: var(--chakra-colors-brand-accent);
}

.branded_radio-button input[type='radio']:checked {
  border: 1px solid var(--chakra-colors-brand-accent);
}
.branded_radio-button input[type='radio']:checked::before {
  transform: scale(1);
}

/*-----------------------------------------*/

/* branded checkbox button styles  */

.branded_checkbox-button {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.branded_checkbox-button input[type='checkbox'] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.branded_checkbox-button .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 1px solid var(--chakra-colors-brand-accent);
}

/* Create the checkmark/indicator (hidden when not checked) */
.branded_checkbox-button .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.branded_checkbox-button input[type='checkbox']:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.branded_checkbox-button .checkmark:after {
  left: 6px;
  top: 3px;
  width: 6px;
  height: 12px;
  border: solid var(--chakra-colors-brand-accent);
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/*-----------------------------------------*/

/* ----------------------------------------*/

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}

/*----------------------------------------*/

.richtext-editor {
  height: 375px !important;
  border: 1px solid #f1f1f1 !important;
  padding: 5px !important;
  border-radius: 2px !important;
  overflow: auto;
}
.quiz-asset-text {
  font-size: 18px;
}

@keyframes loadingFade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
  }
}

/**/

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }
  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }
  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }
  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}

/* Flip card styles */

.flip-card {
  position: relative;
  perspective: 1000px;
  transform-style: preserve-3d;
  transition: transform 0.2s;
}

.card-front-content,
.card-back-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transform: rotateY(0deg);
}

.card-front-content {
  z-index: 2;
}

.card-back-content {
  transform: rotateX(180deg);
}

.flipped {
  transform: rotateX(180deg);
}

/***** Slide Left Animation *****/
.card-slide-left {
  animation: 0.2s fadeInLeft;
}
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/***** Slide Right Animation *****/
.card-slide-right {
  animation: 0.3s fadeInRight;
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.question-box-animate-in {
  animation: 0.7s fadeInRight;
}
